import * as React from "react";
import { Header, Container, Segment, Icon, Button } from "semantic-ui-react";
import {withLayout} from "../components/Layout";
//import downloadFile from '../../static/TinyOS-UnStackedC-Version-0.1.tar.gz'


const DownloadPage = () => {
  return (
    <Container>
      <Segment vertical>
        <Header as="h2">
          <Icon name="cloud download" />
          <Header.Content>
            Downloads
          </Header.Content>
        </Header>
      </Segment>
      <Segment vertical>

        <p>
          Currently we only have an alpha release for TinyOS available.
        </p>
        <p>
          TinyOS (Cross platform) Download:
        </p>
        <p>
          <Button href={"/"+`TinyOS-UnStackedC-Version-0.1.tar.gz`} download>Download Now</Button>
        </p>
      </Segment>
      <Segment vertical>
        <Header as="h2">
          <Icon name="configure" />
          <Header.Content>
            Installation Instructions
          </Header.Content>
        </Header>
      </Segment>
      <Segment vertical>
        <Header as="h3">TinyOS Installation Instructions</Header>
        <p>
          To install UnStackedC download the tarball from here.

          </p>
        <p>
          Then the tarball must be extracted directly over the existing TinyOS installation. Only one file ($TOSROOT/support/make/msp/msp.rules) which you may want to backup prior to installation.
        </p>
        <p>
          The command to install this package is
        </p>
        <pre style={{color:"#F0FFF0"}}>{`
          cd $TOSROOT

          tar -xvzf /path/to/TinyOS-UnStackedC-Version-0.1.tar.gz
        `}</pre>
        <p>Once the installation is successful, then feel free to use unstacked C in any of the demo applications in $TOSROOT/apps/tosthreads/apps.</p>
      </Segment>
      <Segment vertical>
        <Header as="h3">Usage Instructions</Header>
        <p>UnStacked C installs along side your existing TOSThreads installation. So once installed, one can compile using regular tosthreads in this manor:</p>
        <pre>
        make telosb threads
        </pre>
        <p>To compile a given application with UnStacked C:</p>

        <pre>make telosb unstackedc</pre>
        <p>
        So the results can easily be compared.
        </p>
        <p>If you have any problems check the <a href={"/faq"}>FAQ</a>.</p>
      </Segment>
      <Segment vertical>
        <Header as="h3">PC Installation Instructions</Header>
        Coming Soon
      </Segment>
      <Segment vertical>
        <Header as="h3">Embedded Development Instructions</Header>
        Coming Soon
      </Segment>
    </Container>
  );
};

export default withLayout(DownloadPage);
